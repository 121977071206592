<template>
  <div class="basic-data-add">
    <header-path :paths="headerPaths" />
    <el-card>
      <div class="baseinfo">
        <div class="baseinfo-title">
          {{ $t('lang.gles.common.basicInfo') }}
        </div>
        <m-form
          ref="myForm"
          :form-data="baseFormData"
          label-position="right"
          :label-width="100"
          :extend-config="extendFormConfig"
        />
      </div>
      <div class="stockinfo">
        <div class="stockinfo-head">
          <div class="stockinfo-title">
            <!-- 库存调整明细 -->
            {{ $t('lang.gles.orderManage.stockAdjustDetail') }}
          </div>
          <el-button type="primary" icon="el-icon-plus" @click="onAdd">
            {{ $t('lang.gles.common.addNew') }}
          </el-button>
        </div>
        <m-table
          border
          :table-item="tableItem"
          :table-data="tableData"
          :extend-config="tableConfig"
          :page-data="pageData"
          @deleteItem="deleteItem"
        >
          <template #clickSlot="{ row }">
            <span
              v-if="row.materialId"
              class="detail-item"
              @click="onAttr(row)"
            >
              {{ $t('lang.gles.common.view') }}
            </span>
          </template>
        </m-table>
      </div>
      <div class="handle">
        <el-button size="small" class="w70" @click="onCancel">
          {{ $t('lang.gles.upDownBillStatus.cancel') }}
        </el-button>
        <el-button type="primary" class="w70" size="small" @click="onSubmit">
          {{ $t('lang.gles.common.save') }}
        </el-button>
      </div>
    </el-card>
    <!-- 详情 -->
    <add-item
      v-if="addDialogVisible"
      :visible.sync="addDialogVisible"
      :options="options"
      @saveSuccess="saveSuccess"
    />
    <batch-property-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :mode="mode"
      :init-row="initRow"
      :list="batchPropertyDetail"
    />
  </div>
</template>
<script>
import myTransform from '@/libs_sz/utils/transform'
import HeaderPath from '@/components/headerPath/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import AddItem from './addItem.vue'
import BatchPropertyDialog from './batchPropertyDialog'
import { getEditBaseFormData, getDetailTableItems } from '../data'
import addMixins from '@/mixins/addMixins'

export default {
  name: 'AreaAdd',
  components: {
    MForm,
    AddItem,
    HeaderPath,
    BatchPropertyDialog
  },
  mixins: [addMixins],
  props: {
    // 新增/修改模式
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      addDialogVisible: false,
      detailDialogVisible: false,
      batchPropertyDetail: [],
      initRow: {},
      extendFormConfig: {
        isNeedBtn: false
      },
      tableData: [],
      tableConfig: {
        sortNum: true,
        checkBox: false,
        operateWidth: '80px',
        operate: [
          {
            event: 'deleteItem',
            label: this.$t('lang.gles.common.delete'),
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0')
          }
        ]
      },
      pageData: null,
      newContainerArchivesAllList: [],
      newGoodsPositionList: [],
      newFactoryPositionList: [],
      newMaterialCodeList: [],
      newMaterialNameList: [],
      newMovableContainerTypeList: [],
      newTurnoverContainerTypeList: [],
      newFixedContainerTypeList: []
    }
  },
  computed: {
    headerPaths() {
      return this.mode === 'add'
        ? [this.$t('lang.gles.orderManage.storeAdjustAdd')]
        : [this.$t('lang.gles.orderManage.storeAdjustEdit')]
    },
    baseFormData() {
      const row = this.rowDetail || {}
      const options = {
        ...this.options
      }
      return getEditBaseFormData(this, { ...row }, options)
    },
    tableItem() {
      return getDetailTableItems(this, this.options)
    }
  },
  created() {
    this.updateNewData()
    this.formModel = { ...this.rowDetail }
    if (this.mode === 'edit') {
      this.getStockAdjustOrderDetail(this.rowDetail.id)
    }
  },
  methods: {
    updateNewData() {
      // 更新最新移动容器编码和货位编码
      this.getContainerArchivesAllList()
      this.getGoodsPositionListByType()
      this.getFactoryPositionDictList()
      this.getMaterialCodeList()
      this.getNewContainerTypeList(
        [
          'containerPatternShelf',
          'containerPatternTray'
          // 'containerPatternRobotComponents'
        ],
        'newMovableContainerTypeList'
      )
      this.getNewContainerTypeList(
        ['containerPatternBarrel', 'containerPatternCrate'],
        'newTurnoverContainerTypeList'
      )
      // // 固定容器类型
      // this.getNewContainerTypeList(
      //   ['containerPatternFixedShelf', 'containerPatternStackingShelves'],
      //   'newFixedContainerTypeList'
      // )
    },
    // 获取最新容器类型
    async getNewContainerTypeList(queryData, array) {
      const params = {
        containerTypePatternList: queryData
      }
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getContainerType'),
        params
      )
      if (code) return
      this[array] = myTransform.arrToOptions(
        data,
        'containerTypeName',
        'containerTypeId'
      )
    },
    // 所有容器编码
    async getContainerArchivesAllList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('getContainerArchivesAllList')
      )
      if (code) return
      this.newContainerArchivesAllList =
        data &&
        data.map((item) => {
          return { label: item.containerArchivesCode, value: item.id }
        })
    },
    // 货位编码
    async getGoodsPositionListByType() {
      const { data } = await this.$httpService(
        this.$apiStore.base('getGoodsPositionListByType')
      )
      const list = data.map((item) => ({
        label: item.code,
        value: item.id,
        ...item
      }))
      this.newGoodsPositionList = list
    },
    // 库位编码
    async getFactoryPositionDictList() {
      const { data } = await this.$httpService(
        this.$apiStore.base('getFactoryPositionDictList')
      )
      const list = data.map((item) => ({
        label: item.factoryPositionCode,
        value: item.id,
        ...item
      }))
      this.newFactoryPositionList = list
    },
    // 获取物料编码的下拉数据
    async getMaterialCodeList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('queryMaterialOption')
      )
      if (code) return
      this.newMaterialCodeList = data.map((item) => {
        return { label: item.materialCode, value: item.id }
      })
      this.newMaterialNameList = data.map((item) => {
        return { label: item.materialName, value: item.id }
      })
    },
    // 查询库存调整单明细
    async getStockAdjustOrderDetail(id) {
      const params = {
        orderId: id
      }
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('getStockAdjustOrderDetail'),
        params
      )
      if (code) return
      data.forEach((item) => {
        item.batchProperty = item.batchProperty || null
        item.materialAdjustNum =
          item.materialAdjustNum !== -1 ? item.materialAdjustNum : null
        item.materialAvailableNum =
          item.materialAvailableNum !== -1 ? item.materialAvailableNum : null
        item.materialMeasuringAdjustNum =
          item.materialMeasuringAdjustNum !== -1
            ? item.materialMeasuringAdjustNum
            : null
        item.materialMeasuringAvailableNum =
          item.materialMeasuringAvailableNum !== -1
            ? item.materialMeasuringAvailableNum
            : null
        item.movableContainerAdjustNum =
          item.movableContainerAdjustNum !== -1
            ? item.movableContainerAdjustNum
            : null
        item.movableContainerAvailableNum =
          item.movableContainerAvailableNum !== -1
            ? item.movableContainerAvailableNum
            : null
        item.turnoverContainerAdjustNum =
          item.turnoverContainerAdjustNum !== -1
            ? item.turnoverContainerAdjustNum
            : null
        item.turnoverContainerAvailableNum =
          item.turnoverContainerAvailableNum !== -1
            ? item.turnoverContainerAvailableNum
            : null
        item.fixedContainerId =
          item.fixedContainerId === 0 ? null : item.fixedContainerId
        item.materialGradeStatus =
          item.materialGradeStatus === 0 ? null : item.materialGradeStatus
        item.materialId = item.materialId === 0 ? null : item.materialId
        item.movableContainerGoodsPositionId =
          item.movableContainerGoodsPositionId === 0
            ? null
            : item.movableContainerGoodsPositionId
        item.turnoverContainerId =
          item.turnoverContainerId === 0 ? null : item.turnoverContainerId
        item.turnoverContainerTypeId =
          item.turnoverContainerTypeId === 0
            ? null
            : item.turnoverContainerTypeId
        item.materialName = item.materialId
      })
      this.tableData = data || []
    },
    // 新增
    onAdd(row) {
      this.addDialogVisible = true
      this.row = row
    },
    dataFun(data) {
      const obj = this._.cloneDeep(data)
      for (const key in obj) {
        if (obj[key] === '') {
          obj[key] = null
        }
      }
      return obj
    },
    // isRepeatFun(newData, oldData) {
    //   let flag = false
    //   for (const key in newData) {
    //     if (newData[key] !== oldData[key]) {
    //       flag = true
    //       break
    //     }
    //   }
    //   return flag
    // },
    // 保存回调
    saveSuccess(data) {
      this.updateNewData()
      const newData = []
      data.forEach((element) => {
        if (
          element.batchProperty &&
          typeof element.batchProperty !== 'string'
        ) {
          element.batchProperty = Object.keys(element.batchProperty).length
            ? JSON.stringify(element.batchProperty)
            : null
        }
        element = this.dataFun(element)
        newData.push(element)
      })
      if (this.tableData.length) {
        let isRepeat = false
        this.tableData.forEach((item) => {
          const newItem = Object.assign({}, item)
          if (
            newItem.batchProperty &&
            typeof newItem.batchProperty === 'string'
          ) {
            newItem.batchProperty = JSON.parse(newItem.batchProperty)
          }
          delete newItem.addIndex
          delete newItem.materialAdjustNum
          delete newItem.materialAvailableNum
          delete newItem.materialMeasuringAdjustNum
          delete newItem.materialMeasuringAvailableNum
          delete newItem.movableContainerAdjustNum
          delete newItem.movableContainerAvailableNum
          delete newItem.turnoverContainerAdjustNum
          delete newItem.turnoverContainerAvailableNum
          delete newItem.factoryId
          delete newItem.warehouseId
          delete newItem.workshopId
          delete newItem.warehouseAreaId
          delete newItem.containerTypeId
          delete newItem.containerArchivesId
          delete newItem.materialBasicUnit
          delete newItem.materialMeasuringUnit
          delete newItem.goodsPositionId
          delete newItem.goodsPositionStatus
          delete newItem.factoryPositionStatus
          if (item.adjustTarget !== 1) {
            delete newItem.factoryPositionId
          }
          if (item.id) {
            delete newItem.id
            delete newItem.orderId
            delete newItem.areaId
            delete newItem.productionLineId
            delete newItem.fixedGoodsPositionCode
            delete newItem.movableContainerGoodsPositionCode
            delete newItem.materialBatchNumber
          }
          newData.forEach((cItem) => {
            const newCItem = Object.assign({}, cItem)
            if (
              newCItem.batchProperty &&
              typeof newCItem.batchProperty === 'string'
            ) {
              newCItem.batchProperty = JSON.parse(newCItem.batchProperty)
            }
            delete newCItem.addIndex
            delete newCItem.materialAdjustNum
            delete newCItem.materialAvailableNum
            delete newCItem.materialMeasuringAdjustNum
            delete newCItem.materialMeasuringAvailableNum
            delete newCItem.movableContainerAdjustNum
            delete newCItem.movableContainerAvailableNum
            delete newCItem.turnoverContainerAdjustNum
            delete newCItem.turnoverContainerAvailableNum
            delete newCItem.goodsPositionStatus
            delete newCItem.goodsPositionId
            delete newCItem.factoryPositionStatus
            delete newCItem.factoryId
            delete newCItem.warehouseId
            delete newCItem.workshopId
            delete newCItem.warehouseAreaId
            delete newCItem.containerTypeId
            delete newCItem.containerArchivesId
            delete newCItem.materialMeasuringUnit
            delete newCItem.materialBasicUnit
            if (cItem.adjustTarget !== 1) {
              delete newCItem.factoryPositionId
            }
            // console.log(newItem, newCItem)
            if (this._.isEqual(newItem, newCItem)) {
              isRepeat = true
              return
            }
          })
        })

        if (isRepeat) {
          this.$message.error(
            this.$t('lang.gles.orderManage.adjustOrderHandleTips1')
          )
          return
        }
      }
      console.log(newData)
      this.tableData = this.tableData.concat(newData)
    },
    // 删除
    async deleteItem({ row, $index }) {
      console.log(row)
      const { id } = row
      if (id) {
        const { code } = await this.$httpService(
          this.$apiStore.orderManage('deleteOrderDetail'),
          { id }
        )
        if (code) return
        this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      }
      this.tableData.splice($index, 1)
    },
    // 查看批属性
    async onAttr(row) {
      const params = {
        id: row.materialId
      }
      this.initRow = row.batchProperty ? JSON.parse(row.batchProperty) : {}
      console.log(this.initRow)
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('queryMaterialDetail'),
        params
      )
      if (code) return
      this.batchPropertyDetail = data.batchPropertyDetail || []
      if (this.batchPropertyDetail.length) {
        this.detailDialogVisible = true
      } else {
        this.$message.error(this.$t('lang.gles.orderManage.batchPropertyTips'))
      }
    },
    // 保存
    async onSubmit() {
      const formModel = this.$refs.myForm.getFormModel()
      // console.log(formModel)
      // type 1 手工调整 source 1 手工创建
      if (!this.tableData.length) {
        this.$message.error(
          this.$t('lang.gles.orderManage.pleaseAddAdjustOrder')
        )
        return
      }
      // this.tableData.forEach(element => {
      //   element.batchProperty = JSON.stringify(element.batchProperty)
      // })
      const params = {
        type: formModel.type,
        source: 1,
        remark: formModel.remark,
        orderDetails: this.tableData
      }
      if (this.mode === 'edit') {
        params.id = this.rowDetail.id
      }
      const { code } = await this.$httpService(
        this.$apiStore.orderManage('addOrderDetail'),
        params
      )
      if (code) return
      this.onCancel()
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
    },
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'StockAdjustmentOrderList'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.stockinfo-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.baseinfo-title {
  margin-bottom: 10px;
}
.baseinfo-title,
.stockinfo-title {
  font-weight: 800;

  // &::before {
  //   content: '';
  //   display: inline-block;
  //   height: 21px;
  //   width: 4px;
  //   border-radius: 4px;
  //   background: #409eff;
  //   margin-right: 10px;
  //   vertical-align: text-bottom;
  // }
}
.detail-item {
  cursor: pointer;
  color: #409eff;
}
.handle {
  margin-top: 15px;
  text-align: center;
}
</style>

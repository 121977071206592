<template>
  <div class="basic-data-list">
    <el-card class="common-search-card">
      <div class="search-input">
        <el-input
          v-model="formModel.orderNumber"
          placeholder="lang.gles.orderManage.pleaseInputNo"
          class="input-with-select"
        >
          <el-button slot="append" type="primary" @click="onSearch(formModel)">
            {{ $t('lang.gles.common.query') }}
          </el-button>
        </el-input>
      </div>
      <el-button @click="moreQueryVisible = true">
        {{ $t('lang.gles.common.moreQuery') }}
      </el-button>
    </el-card>
    <!-- 更多查询 -->
    <more-query
      ref="myQueryView"
      :query-list="moreQueryForm"
      :visible.sync="moreQueryVisible"
      @query="handleMoreQuery"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="addItem">
          {{ $t('lang.gles.common.addNew') }}
        </el-button>
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableConfig"
        @pageChange="pageChange"
      >
        <template #clickSlot="{ row }">
          <span class="list-detail-item" @click="handleDetail(row)">
            {{ row.orderNumber }}
          </span>
        </template>
        <template #adjustSlot="{ row, item }">
          <m-confirm-btn
            :key="item.label"
            :label="item.label"
            :message="item.confirmMessage"
            type="text"
            :disabled="row.status !== 0"
            :confirm-class="'mr10 ml0 f12 ' + (item.customClass || '')"
            @sureClick="() => _operateClick(item.event, row)"
          />
        </template>
        <template #editSlot="{ row, item }">
          <el-button
            class="mr10"
            type="text"
            :disabled="row.status !== 0"
            @click="_operateClick(item.event, row)"
          >
            {{ item.label }}
          </el-button>
        </template>
        <template #deleteSlot="{ row, item }">
          <m-confirm-btn
            :key="item.label"
            :label="item.label"
            :message="item.confirmMessage"
            type="text"
            :disabled="row.status !== 0"
            :confirm-class="'mr10 ml0 f12 ' + (item.customClass || '')"
            @sureClick="() => _operateClick(item.event, row)"
          />
        </template>
      </m-table>
    </el-card>
    <!-- 详情 -->
    <detail-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :row-detail="row"
      :options="detailOptions"
    />
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import MoreQuery from '@/components/moreQuery'
import MTable from '@/libs_sz/components/MTable/MTable'
import MConfirmBtn from '@/libs_sz/components/MConfirmBtn/MConfirmBtn'
import DetailDialog from './detail'
import {
  getMoreQueryFormData,
  getSearchTableItem,
  adjustReason,
  adjustTargetList,
  stockStatusList
} from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import myTransform from '@/libs_sz/utils/transform'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'StockAdjustmentOrder',
  components: {
    ColumnConfig,
    MoreQuery,
    MTable,
    MConfirmBtn,
    DetailDialog
  },
  mixins: [listMixins],
  data() {
    return {
      stockStatusList,
      adjustReason,
      adjustTargetList,
      detailDialogVisible: false,
      tableData: [],
      formModel: {},
      tableConfig: {
        sortNum: true,
        checkBox: false,
        operateWidth: '158px',
        operate: [
          {
            event: 'adjustItem',
            label: this.$t('lang.gles.common.sureAdjust'),
            slotName: 'adjustSlot',
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.wantExecuteThisAdjust')
          },
          {
            event: 'editItem',
            label: this.$t('lang.gles.common.edit'),
            slotName: 'editSlot'
          },
          {
            event: 'deleteItem',
            label: this.$t('lang.gles.common.delete'),
            slotName: 'deleteSlot',
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0')
          }
        ]
      },
      // 工厂
      factoryList: [],
      // 车间
      workshopList: [],
      // 仓库
      warehouseList: [],
      // 库区
      warehouseAreaList: [],
      // 库位
      factoryPositionList: [],
      // 区域
      areaList: [],
      // 产线
      productionLineList: [],
      // 设备
      deviceList: [],
      // 设备货位
      goodsPositionList: [],
      // 移动容器类型
      movableContainerTypeList: [],
      // 周转容器类型
      turnoverContainerTypeList: [],
      // 固定容器类型
      fixedContainerTypeList: [],
      // 物料编码
      materialCodeList: [],
      // 物料名称
      materialNameList: [],
      // 所有容器编码
      containerArchivesAllList: [],

      // 查询专用
      queryData: {},
      // 车间
      queryWorkshopList: [],
      // 仓库
      queryWarehouseList: [],
      // 库区
      queryWarehouseAreaList: [],
      // 库位
      queryFactoryPositionList: [],
      // 设备货位
      queryGoodsPositionList: []
    }
  },
  computed: {
    ...mapState('base', ['stockAdjustOrderStatusList', 'gradeStatusList']),
    ...mapState('materialManage', ['basicUnitList']),
    moreQueryForm() {
      return getMoreQueryFormData(this, this.formModel)
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this, this.detailOptions)
    },
    detailOptions() {
      const options = {
        factoryList: this.factoryList,
        workshopList: this.workshopList,
        warehouseList: this.warehouseList,
        warehouseAreaList: this.warehouseAreaList,
        factoryPositionList: this.factoryPositionList,
        areaList: this.areaList,
        productionLineList: this.productionLineList,
        deviceList: this.deviceList,
        goodsPositionList: this.goodsPositionList,
        adjustReason: this.adjustReason,
        gradeStatusList: this.gradeStatusList,
        statusCode_dict: this.statusCode_dict,
        adjustTargetList: this.adjustTargetList,
        movableContainerTypeList: this.movableContainerTypeList,
        turnoverContainerTypeList: this.turnoverContainerTypeList,
        fixedContainerTypeList: this.fixedContainerTypeList,
        materialCodeList: this.materialCodeList,
        materialNameList: this.materialNameList,
        containerArchivesAllList: this.containerArchivesAllList,
        stockStatusList: this.stockStatusList,
        stockAdjustOrderStatusList: this.stockAdjustOrderStatusList,
        basicUnitList: this.basicUnitList
      }
      return options
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  watch: {
    formModel: {
      handler() {
        this.$nextTick(() => {
          this.$refs.myQueryView?.$refs?.moreQueryData.changeFormValue(
            this.formModel ?? {}
          )
        })
      },
      deep: true
    }
  },
  async created() {
    this.doSearch(this.formModel)
    this.initSelectList()
  },
  methods: {
    ...mapActions('materialManage', ['getBasicUnitList']),
    /**
     * 初始化下拉选项
     */
    initSelectList() {
      this.getBaseData('getFactoryDictList', 'factoryList', 'factoryCode')
      this.getBaseData('getWarehouseDictList', 'warehouseList', 'warehouseCode')
      this.getBaseData('getWorkshopDictList', 'workshopList', 'workshopCode')
      // this.getBaseData('getAreaDictList', 'areaList', 'areaCode')
      // this.getBaseData(
      //   'getProductionLineDictList',
      //   'productionLineList',
      //   'productionLineCode'
      // )
      this.getBaseData(
        'getWarehouseAreaDictList',
        'warehouseAreaList',
        'warehouseAreaCode'
      )
      this.getBaseData(
        'getFactoryPositionDictList',
        'factoryPositionList',
        'factoryPositionCode'
      )
      this.getBaseData('getDeviceDictList', 'deviceList', 'deviceCode')
      this.getBaseData(
        'getGoodsPositionListByType',
        'goodsPositionList',
        'goodsPositionCode'
      )

      this.getContainerTypeList(
        [
          'containerPatternShelf',
          'containerPatternTray'
          // 'containerPatternRobotComponents'
        ],
        'movableContainerTypeList'
      )
      this.getContainerTypeList(
        ['containerPatternBarrel', 'containerPatternCrate'],
        'turnoverContainerTypeList'
      )
      // 固定容器类型
      this.getContainerTypeList(
        ['containerPatternFixedShelf', 'containerPatternStackingShelves'],
        'fixedContainerTypeList'
      )
      this.getMaterialCodeList()
      this.getContainerArchivesAllList()
      if (!this.stockAdjustOrderStatusList?.length) {
        this.$store.dispatch('base/getStockAdjustOrderStatusList')
      }
      if (!this.basicUnitList?.length) {
        this.getBasicUnitList()
      }
      if (!this.gradeStatusList?.length) {
        this.$store.dispatch('base/getGradeStatusList')
      }
    },
    resetQueryList() {
      this.queryWorkshopList = this.queryData.queryWorkshopList
      this.queryWarehouseList = this.queryData.queryWarehouseList
      this.queryWarehouseAreaList = this.queryData.queryWarehouseAreaList
      this.queryFactoryPositionList = this.queryData.queryFactoryPositionList
      this.queryGoodsPositionList = this.queryData.queryGoodsPositionList
    },
    // 切换工厂、仓库、车间、库区、库位、货位
    handleChange(val, formItem) {
      this.$set(this.formModel, formItem.name, val)
      if (formItem.name === 'factoryId') {
        this.queryWorkshopList = []
        this.queryWarehouseList = []
        this.queryWarehouseAreaList = []
        this.queryFactoryPositionList = []
        this.queryGoodsPositionList = []
        const params = {
          factoryId: val
        }
        if (val) {
          this.getBaseData(
            'getWarehouseDictList',
            'queryWarehouseList',
            'warehouseCode',
            params
          )
          this.getBaseData(
            'getWorkshopDictList',
            'queryWorkshopList',
            'workshopCode',
            params
          )
        } else {
          this.resetQueryList()
        }
        this.formModel.warehouseId = ''
        this.formModel.workshopId = ''
        this.formModel.warehouseAreaId = ''
        this.formModel.factoryPositionId = ''
        this.formModel.goodsPositionId = ''
      } else if (formItem.name === 'warehouseId') {
        this.queryWarehouseAreaList = []
        this.queryFactoryPositionList = []
        this.queryGoodsPositionList = []
        const params = {
          warehouseId: val
        }
        if (val) {
          this.getBaseData(
            'getWarehouseAreaDictList',
            'queryWarehouseAreaList',
            'warehouseAreaCode',
            params
          )
        } else {
          if (!this.formModel.factoryId) {
            this.resetQueryList()
          }
        }
        this.formModel.warehouseAreaId = ''
        this.formModel.factoryPositionId = ''
        this.formModel.goodsPositionId = ''
      } else if (formItem.name === 'workshopId') {
        this.queryWarehouseAreaList = []
        this.queryFactoryPositionList = []
        this.queryGoodsPositionList = []
        const params = {
          workshopId: val
        }
        if (val) {
          this.getBaseData(
            'getWarehouseAreaDictList',
            'queryWarehouseAreaList',
            'warehouseAreaCode',
            params
          )
        } else {
          if (!this.formModel.factoryId) {
            this.resetQueryList()
          }
        }
        this.formModel.warehouseAreaId = ''
        this.formModel.factoryPositionId = ''
        this.formModel.goodsPositionId = ''
      } else if (formItem.name === 'warehouseAreaId') {
        this.queryFactoryPositionList = []
        this.queryGoodsPositionList = []
        const params = {
          warehouseAreaId: val
        }
        if (val) {
          this.getBaseData(
            'getFactoryPositionDictList',
            'queryFactoryPositionList',
            'factoryPositionCode',
            params
          )
        } else {
          if (
            !this.formModel.factoryId &&
            !this.formModel.warehouseId &&
            !this.formModel.workshopId
          ) {
            this.resetQueryList()
          }
        }
        this.formModel.factoryPositionId = ''
        this.formModel.goodsPositionId = ''
      } else {
        this.queryGoodsPositionList = []
        const params = {
          factoryPositionId: val
        }
        if (val) {
          this.getBaseData(
            'getGoodsPositionListByType',
            'queryGoodsPositionList',
            'goodsPositionCode',
            params
          )
        } else {
          if (
            !this.formModel.factoryId &&
            !this.formModel.warehouseId &&
            !this.formModel.workshopId &&
            !this.formModel.warehouseAreaId
          ) {
            this.resetQueryList()
          }
        }
        this.formModel.goodsPositionId = ''
      }
    },
    // 获取容器类型
    async getContainerTypeList(queryData, array) {
      const params = {
        containerTypePatternList: queryData
      }
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getContainerType'),
        params
      )
      if (code) return
      this[array] = myTransform.arrToOptions(
        data,
        'containerTypeName',
        'containerTypeId'
      )
    },
    // 获取物料编码的下拉数据
    async getMaterialCodeList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('queryMaterialOption')
      )
      if (code) return
      this.materialCodeList = data.map((item) => {
        return { label: item.materialCode, value: item.id }
      })
      this.materialNameList = data.map((item) => {
        return { label: item.materialName, value: item.id }
      })
    },
    // 所有容器编码
    async getContainerArchivesAllList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('getContainerArchivesAllList')
      )
      if (code) return
      this.containerArchivesAllList =
        data &&
        data.map((item) => {
          return { label: item.containerArchivesCode, value: item.id }
        })
    },
    async getBaseData(url, array, label, params = {}) {
      const { data } = await this.$httpService(this.$apiStore.base(url), params)
      const list = data.map((item) => ({
        label: label === 'factoryPositionCode' ? item[label] : item.code,
        value: item.id,
        ...item
      }))
      this[array] = list
      switch (array) {
        case 'workshopList':
          this.queryWorkshopList = list
          this.queryData.queryWorkshopList = list
          break
        case 'warehouseList':
          this.queryWarehouseList = list
          this.queryData.queryWarehouseList = list
          break
        case 'warehouseAreaList':
          this.queryWarehouseAreaList = list
          this.queryData.queryWarehouseAreaList = list
          break
        case 'factoryPositionList':
          this.queryFactoryPositionList = list
          this.queryData.queryFactoryPositionList = list
          break
        case 'goodsPositionList':
          this.queryGoodsPositionList = list
          this.queryData.queryGoodsPositionList = list
          break
        default:
          break
      }
      // console.log(this[array], array)
    },
    /**
     * 查看详情
     */
    handleDetail(row) {
      this.detailDialogVisible = true
      this.row = row
      this.mode = 'detail'
    },
    /**
     * 更多查询
     */
    handleMoreQuery(event) {
      // console.log(event)
      this.formModel = { ...event }
      if (event.createTime && event.createTime.length) {
        event.createTimeStart = event.createTime[0]
        event.createTimeEnd = event.createTime[1]
      }
      if (event.updateTime && event.updateTime.length) {
        event.updateTimeStart = event.updateTime[0]
        event.updateTimeEnd = event.updateTime[1]
      }
      if (event.completeTime && event.completeTime.length) {
        event.completeTimeStart = event.completeTime[0]
        event.completeTimeEnd = event.completeTime[1]
      }
      const obj = {
        ...event
      }
      delete obj.createTime
      delete obj.updateTime
      delete obj.completeTime
      this.pageData.currentPage = 1
      this.doSearch({ ...obj }, 'getStockAdjustOrder')
      // this.$refs.myQueryView.reset()
    },
    onSearch() {
      this.pageData.currentPage = 1
      this.doSearch(this.formModel)
    },
    /**
     * 接口查询
     */
    async doSearch(params, api = 'getStockAdjustOrder') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.orderManage(api, this.pageData),
          params
        )
        this.$store.commit(types.IS_LOADING, false)
        if (code) return
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount: totalRecordCount }
        this.tableData = recordList || []
        console.log(this.tableData)
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    _operateClick(event, row) {
      this[event]({ row })
    },
    /**
     * 确认调整
     */
    async adjustItem({ row }) {
      const { id } = row
      const { code } = await this.$httpService(
        this.$apiStore.orderManage('confirmExecution'),
        { id }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.handleSuccess'))
      this.doSearch({ ...this.formModel })
    },
    /**
     * 新增
     */
    addItem({ row }) {
      this.$emit('updateCom', {
        currentCom: 'StockAdjustmentOrderAdd',
        mode: 'add',
        row: {
          type: 1
        },
        options: this.detailOptions
      })
    },
    /**
     * 编辑
     */
    editItem({ row }) {
      this.$emit('updateCom', {
        currentCom: 'StockAdjustmentOrderAdd',
        mode: 'edit',
        row: {
          type: 1,
          ...row
        },
        options: this.detailOptions
      })
    },
    /**
     * 删除
     */
    async deleteItem({ row }) {
      console.log(row)
      const { id } = row
      const { code } = await this.$httpService(
        this.$apiStore.orderManage('deleteOrder'),
        { id }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      this.doSearch({ ...this.formModel })
    },
    pageChange(pageData) {
      this.pageData = { ...this.pageData, ...pageData }
      this.doSearch(this.formModel)
    }
  }
}
</script>

<template>
  <el-drawer :visible="visible" size="80%" custom-class="gles-drawer" :with-header="false" @update:visible="$emit('update:visible', $event)">
    <div class="drawer-wrapper">
      <div class="drawer-operate-btn">
        <!-- <el-button type="primary" @click="handleEdit">
          {{ $t('lang.gles.common.edit') }}
        </el-button> -->
        <!-- <el-button type="danger" @click="handleDelete">
          {{ $t('lang.gles.common.delete') }}
        </el-button> -->
        <i class="el-icon-close" @click="$emit('update:visible', false)" />
      </div>
      <div class="baseinfo">
        <div class="baseinfo-title">
          {{ $t('lang.gles.common.basicInfo') }}
        </div>
        <m-form
          ref="myForm"
          :form-data="baseFormData"
          label-position="right"
          :label-width="100"
          :extend-config="extendFormConfig"
        />
      </div>
      <div class="stockinfo">
        <div class="stockinfo-head">
          <div class="stockinfo-title">
            <!-- 库存调整明细 -->
            {{ $t('lang.gles.orderManage.stockAdjustDetail') }}
          </div>
          <!-- <el-button type="primary" icon="el-icon-plus" @click="onAdd">
            {{ $t('lang.gles.common.addNew') }}
          </el-button> -->
        </div>
        <m-table
          border
          :table-item="tableItem"
          :table-data="tableData"
          :page-data="pageData"
        >
          <template #clickSlot="{ row }">
            <span v-if="row.materialId" class="detail-item" @click="onAttr(row)">
              {{ $t('lang.gles.common.view') }}
            </span>
          </template>
        </m-table>
      </div>
      <!-- <div class="handle">
        <el-button size="small" class="w70" @click="onCancel">
          {{ $t('lang.gles.upDownBillStatus.cancel') }}
        </el-button>
        <el-button type="primary" class="w70" size="small" @click="onSubmit">
          {{ $t('lang.gles.common.save') }}
        </el-button>
      </div> -->
    </div>
    <batch-property-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :mode="mode"
      :init-row="initRow"
      :list="batchPropertyDetail"
    />
  </el-drawer>
</template>
<script>
import myTransform from '@/libs_sz/utils/transform'
import MForm from '@/libs_sz/components/MForm/MForm'
import { getEditBaseFormData, getDetailTableItems } from '../data'
import addMixins from '@/mixins/addMixins'
import BatchPropertyDialog from './batchPropertyDialog'

export default {
  name: 'AreaAdd',
  components: {
    MForm,
    BatchPropertyDialog
  },
  mixins: [addMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'detail'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      detailDialogVisible: false,
      batchPropertyDetail: [],
      initRow: {},
      extendFormConfig: {
        isNeedBtn: false
      },
      tableData: [],
      pageData: null,
      newContainerArchivesAllList: [],
      newGoodsPositionList: [],
      newFactoryPositionList: [],
      newMaterialCodeList: [],
      newMaterialNameList: []
    }
  },
  computed: {
    baseFormData() {
      const row = this.rowDetail || {}
      const options = {
        ...this.options
      }
      return getEditBaseFormData(this, { ...row }, options)
    },
    tableItem() {
      return getDetailTableItems(this, this.options)
    }
  },
  created() {
    this.updateNewData()
    this.formModel = { ...this.rowDetail }
    this.getStockAdjustOrderDetail(this.rowDetail.id)
  },
  methods: {
    updateNewData() {
      // 更新最新移动容器编码和货位编码
      this.getContainerArchivesAllList()
      this.getGoodsPositionListByType()
      this.getFactoryPositionDictList()
      this.getMaterialCodeList()
      this.getNewContainerTypeList(
        [
          'containerPatternShelf',
          'containerPatternTray'
          // 'containerPatternRobotComponents'
        ],
        'newMovableContainerTypeList'
      )
      this.getNewContainerTypeList(
        ['containerPatternBarrel', 'containerPatternCrate'],
        'newTurnoverContainerTypeList'
      )
      // 固定容器类型
      // this.getNewContainerTypeList(
      //   ['containerPatternFixedShelf', 'containerPatternStackingShelves'],
      //   'newFixedContainerTypeList'
      // )
    },
    // 获取最新容器类型
    async getNewContainerTypeList(queryData, array) {
      const params = {
        containerTypePatternList: queryData
      }
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getContainerType'),
        params
      )
      if (code) return
      this[array] = myTransform.arrToOptions(
        data,
        'containerTypeName',
        'containerTypeId'
      )
    },
    // 所有容器编码
    async getContainerArchivesAllList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('getContainerArchivesAllList')
      )
      if (code) return
      this.newContainerArchivesAllList =
        data &&
        data.map((item) => {
          return { label: item.containerArchivesCode, value: item.id }
        })
    },
    // 货位编码
    async getGoodsPositionListByType() {
      const { data } = await this.$httpService(this.$apiStore.base('getGoodsPositionListByType'))
      const list = data.map((item) => ({
        label: item.code,
        value: item.id,
        ...item
      }))
      this.newGoodsPositionList = list
    },
    // 库位编码
    async getFactoryPositionDictList() {
      const { data } = await this.$httpService(this.$apiStore.base('getFactoryPositionDictList'))
      const list = data.map((item) => ({
        label: item.factoryPositionCode,
        value: item.id,
        ...item
      }))
      this.newFactoryPositionList = list
    },
    // 获取物料编码的下拉数据
    async getMaterialCodeList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('queryMaterialOption')
      )
      if (code) return
      this.newMaterialCodeList = data.map((item) => {
        return { label: item.materialCode, value: item.id }
      })
      this.newMaterialNameList = data.map((item) => {
        return { label: item.materialName, value: item.id }
      })
    },
    // 查询库存调整单明细
    async getStockAdjustOrderDetail(id) {
      const params = {
        orderId: id
      }
      const { code, data } = await this.$httpService(this.$apiStore.orderManage('getStockAdjustOrderDetail'), params)
      if (code) return
      data.forEach(item => {
        item.materialName = item.materialId
        // item.batchProperty = item.batchProperty ? JSON.parse(item.batchProperty) : {}
        item.materialAdjustNum = item.materialAdjustNum !== -1 ? item.materialAdjustNum : null
        item.materialAvailableNum = item.materialAvailableNum !== -1 ? item.materialAvailableNum : null
        item.materialMeasuringAdjustNum = item.materialMeasuringAdjustNum !== -1 ? item.materialMeasuringAdjustNum : null
        item.materialMeasuringAvailableNum = item.materialMeasuringAvailableNum !== -1 ? item.materialMeasuringAvailableNum : null
        item.movableContainerAdjustNum = item.movableContainerAdjustNum !== -1 ? item.movableContainerAdjustNum : null
        item.movableContainerAvailableNum = item.movableContainerAvailableNum !== -1 ? item.movableContainerAvailableNum : null
        item.turnoverContainerAdjustNum = item.turnoverContainerAdjustNum !== -1 ? item.turnoverContainerAdjustNum : null
        item.turnoverContainerAvailableNum = item.turnoverContainerAvailableNum !== -1 ? item.turnoverContainerAvailableNum : null
      })
      this.tableData = data || []
    },
    // 查看批属性
    async onAttr(row) {
      const params = {
        id: row.materialId
      }
      this.initRow = row.batchProperty ? JSON.parse(row.batchProperty) : {}
      const { code, data } = await this.$httpService(this.$apiStore.orderManage('queryMaterialDetail'), params)
      if (code) return
      this.batchPropertyDetail = data.batchPropertyDetail || []
      // this.detailDialogVisible = true
      if (this.batchPropertyDetail.length) {
        this.detailDialogVisible = true
      } else {
        this.$message.error(this.$t('lang.gles.orderManage.batchPropertyTips'))
      }
    },
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'StockAdjustmentOrderList'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.stockinfo-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.baseinfo {
  padding-top: 45px;
}
.baseinfo-title {
  margin-bottom: 10px;
}
.baseinfo-title, .stockinfo-title {
  font-weight: 800;

  &::before {
    content: "";
    display: inline-block;
    height: 21px;
    width: 4px;
    border-radius: 4px;
    background: #409eff;
    margin-right: 10px;
    vertical-align: text-bottom;
  }
}
.detail-item {
  cursor: pointer;
  color: #409eff;
}
.handle {
  margin-top: 15px;
  text-align: center;
}
</style>

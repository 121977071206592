<template>
  <el-drawer
    :visible="visible"
    size="70%"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="drawer-wrapper">
      <el-steps :active="active" align-center class="steps-dom">
        <el-step :title="$t('lang.gles.orderManage.adjustObjClass')" />
        <el-step :title="$t('lang.gles.orderManage.adjustTarget')" />
        <el-step :title="$t('lang.gles.orderManage.adjustDetail')" />
      </el-steps>
      <section v-show="active === 1" class="first-module">
        <div class="flex-c">
          <el-button type="primary" plain class="w100 h40" @click="onSelect(1)">
            <!-- 库位 -->
            {{ $t('lang.gles.base.factoryPosition') }}
          </el-button>
          <el-button type="primary" plain class="w100 h40" @click="onSelect(2)">
            <!-- 固定货位 -->
            {{ $t('lang.gles.strategy.fixGoodsPosition') }}
          </el-button>
          <el-button type="primary" plain class="w100 h40" @click="onSelect(3)">
            <!-- 移动货位 -->
            {{ $t('lang.gles.orderManage.movableGoodsPositionId') }}
          </el-button>
        </div>
      </section>
      <section v-show="active === 2" class="second-module">
        <div>
          <m-form
            ref="secondForm"
            :form-data="formData"
            label-position="right"
            :label-width="100"
            :extend-config="extendFormConfig"
          />
        </div>
      </section>
      <section v-if="active === 3" class="third-module">
        <div class="module-head">
          <m-form
            ref="thirdForm"
            :form-data="adjustDetailForm"
            label-position="right"
            :label-width="100"
            :extend-config="extendFormConfig"
          />
          <div class="module-btn">
            <el-button type="primary" icon="el-icon-plus" @click="onAdd">
              {{ $t('lang.gles.common.addNew') }}
            </el-button>
          </div>
        </div>
        <m-table
          border
          :table-item="tableItem"
          :table-data="tableData"
          :extend-config="tableConfig"
          :page-data="pageData"
          @editItem="editItem"
          @deleteItem="deleteItem"
        >
          <template #clickSlot="{ row }">
            <span
              v-if="row.materialId"
              class="detail-item"
              @click="onAttr(row)"
            >
              {{ $t('lang.gles.common.view') }}
            </span>
          </template>
        </m-table>
      </section>
      <div class="footer-btn">
        <el-button v-show="active === 2" class="w70" @click="onReset">
          {{ $t('lang.gles.common.reset') }}
        </el-button>
        <el-button v-show="active !== 1" class="w70" @click="onPrev">
          {{ $t('lang.gles.orderManage.prevStep') }}
        </el-button>
        <el-button
          v-show="active === 2"
          type="primary"
          class="w70"
          @click="onNext"
        >
          {{ $t('lang.gles.orderManage.nextStep') }}
        </el-button>
        <el-button
          v-show="active === 3"
          type="primary"
          class="w70"
          @click="onSave"
        >
          {{ $t('lang.gles.common.save') }}
        </el-button>
      </div>
    </div>
    <add-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :mode="mode"
      :type="type"
      :init-row="initRow"
      :options="options"
      @saveSuccess="saveSuccess"
    />
    <batch-property-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :mode="mode"
      :init-row="batchPropertyRow"
      :list="batchPropertyDetail"
    />
  </el-drawer>
</template>
<script>
import AddDialog from './addDialog.vue'
import myTransform from '@/libs_sz/utils/transform'
import addMixins from '@/mixins/addMixins'
import BatchPropertyDialog from './batchPropertyDialog'
import {
  getAdjustObjFormData,
  getAdjustDetailFormData,
  getAddDetailTableItems
} from '../data'

export default {
  components: {
    AddDialog,
    BatchPropertyDialog
  },
  mixins: [addMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // 步骤控制变量
      active: 1,
      // 调整类型
      type: '',
      addNum: 0,
      detailDialogVisible: false,
      batchPropertyRow: {},
      batchPropertyDetail: [],
      formModel: {},
      initRow: {},
      editIndex: '',
      dialogVisible: false,
      mode: '',
      adjustDetailRow: {
        goodsPositionStatus: '0',
        factoryPositionStatus: '0'
      },
      extendFormConfig: {
        isNeedBtn: false
      },
      tableData: [],
      saveTableData: [],
      tableConfig: {
        sortNum: true,
        checkBox: false,
        operateWidth: '100px',
        operate: [
          {
            event: 'editItem',
            label: this.$t('lang.gles.common.edit')
          },
          {
            event: 'deleteItem',
            label: this.$t('lang.gles.common.delete'),
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0')
          }
        ]
      },
      pageData: null,
      // 工厂
      factoryList: [],
      // 车间
      workshopList: [],
      // 仓库
      warehouseList: [],
      // 库区
      warehouseAreaList: [],
      // 库位
      factoryPositionList: [],
      // 区域
      areaList: [],
      // 产线
      productionLineList: [],
      // 设备
      deviceList: [],
      // 设备货位
      goodsPositionList: [],
      // 设备货位(在库)
      stockGoodsPositionList: [],
      // 容器编码
      containerArchivesList: [],
      // 容器id
      containerArchivesId: '',
      // 库位id
      factoryPositionId: '',
      // 容器类型（固定货位、移动货位）
      containerTypeList: '',
      newContainerArchivesAllList: [],
      newGoodsPositionList: [],
      newMaterialCodeList: [],
      newMovableContainerTypeList: [],
      newTurnoverContainerTypeList: [],
      newFixedContainerTypeList: [],
      newMaterialNameList: []
    }
  },
  computed: {
    formData() {
      const options = this._.cloneDeep(this.options)
      if (this.type === 2) {
        // 固定容器 只能放托盘和周转容器
        options.movableContainerTypeList = options.movableContainerTypeList.filter(t => t.containerTypePattern === 'containerPatternTray')
      }
      return getAdjustObjFormData(this, { ...this.formModel }, this.options)
    },
    adjustDetailForm() {
      // console.log(this.adjustDetailRow, this.options.goodsPositionList)
      return getAdjustDetailFormData(
        this,
        { ...this.adjustDetailRow },
        this.options
      )
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getAddDetailTableItems(this, this.options)
    },
    tableItem() {
      let list = this.defaultTableItem
      if (this.type === 3) {
        list = this.defaultTableItem.filter((item, i) => i > 4)
      }
      return list
    }
  },
  created() {
    this.getBaseData(
      'getFactoryPositionDictList',
      'factoryPositionList',
      'factoryPositionCode'
    )
    this.updateNewData()
  },
  methods: {
    updateNewData() {
      // 更新最新移动容器编码和货位编码
      this.getContainerArchivesAllList()
      this.getGoodsPositionListByType()
      this.getMaterialCodeList()
      this.getNewContainerTypeList(
        [
          'containerPatternShelf',
          'containerPatternTray'
          // 'containerPatternRobotComponents'
        ],
        'newMovableContainerTypeList'
      )
      this.getNewContainerTypeList(
        ['containerPatternBarrel', 'containerPatternCrate'],
        'newTurnoverContainerTypeList'
      )
      // // 固定容器类型
      // this.getNewContainerTypeList(
      //   ['containerPatternFixedShelf', 'containerPatternStackingShelves'],
      //   'newFixedContainerTypeList'
      // )
    },
    // 获取最新容器类型
    async getNewContainerTypeList(queryData, array) {
      const params = {
        containerTypePatternList: queryData
      }
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getContainerType'),
        params
      )
      if (code) return
      this[array] = myTransform.arrToOptions(
        data,
        'containerTypeName',
        'containerTypeId'
      )
    },
    // 获取容器类型
    async getContainerTypeList() {
      const queryData = this.type === 2 ? [
        'containerPatternFixedShelf',
        'containerPatternStackingShelves'
      ] : [
        'containerPatternShelf',
        'containerPatternTray'
        // 'containerPatternRobotComponents'
      ]
      const params = {
        containerTypePatternList: queryData
      }
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getContainerType'),
        params
      )
      if (code) return
      this.containerTypeList = myTransform.arrToOptions(
        data,
        'containerTypeName',
        'containerTypeId'
      )
    },
    async getBaseData(url, array, label, params = {}) {
      const { data } = await this.$httpService(this.$apiStore.base(url), params)
      const list = data.map((item) => ({
        label: label === 'factoryPositionCode' ? item[label] : item.code,
        value: item.id,
        ...item
      }))
      this[array] = list
      if (array === 'factoryPositionList') {
        this.$store.dispatch('moreData/addFpCodeList', list)
      }
      // console.log(this[array], array)
    },
    // 容器类型选择
    containerTypeChange(val, formItem, cb) {
      // console.log(val, formItem)
      cb({
        containerArchivesId: '',
        goodsPositionId: ''
      })
      this.getContainerArchivesList(val)
    },
    // 获取货位编码
    async queryGoodsPositionOption() {
      const containerTypePatterns =
        this.type === 2 ? ['containerPatternFixedShelf', 'containerPatternStackingShelves']
          : ['containerPatternShelf', 'containerPatternTray']
      const params = {
        containerTypePatterns,
        containerArchivesIds: this.containerArchivesId ? [this.containerArchivesId] : null,
        factoryPositionId: this.factoryPositionId || null
      }
      const { code, data } = await this.$httpService(
        this.$apiStore.inventoryList('queryRobotGoodsPositionCode'),
        params
      )
      if (code) return
      this.stockGoodsPositionList =
        data &&
        data.map((item) => {
          return { label: item.code, value: item.id }
        })
    },
    // 获取容器编码
    async getContainerArchivesList(id) {
      const containerTypePatternType =
        this.type === 2 ? 'fixedContainer' : 'moveContainer'
      const params = {
        containerTypeId: id,
        containerTypePatternType,
        inStore: true
      }
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('getContainerArchivesList'),
        params
      )
      if (code) return
      this.containerArchivesList =
        data &&
        data.map((item) => {
          return { label: item.containerArchivesCode, value: item.id }
        })
    },
    // 所有容器编码
    async getContainerArchivesAllList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('getContainerArchivesAllList')
      )
      if (code) return
      this.newContainerArchivesAllList =
        data &&
        data.map((item) => {
          return { label: item.containerArchivesCode, value: item.id }
        })
    },
    async getGoodsPositionListByType() {
      const { data } = await this.$httpService(this.$apiStore.base('getGoodsPositionListByType'))
      const list = data.map((item) => ({
        label: item.code,
        value: item.id,
        ...item
      }))
      this.newGoodsPositionList = list
    },
    // 获取物料编码的下拉数据
    async getMaterialCodeList() {
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('queryMaterialOption')
      )
      if (code) return
      this.newMaterialCodeList = data.map((item) => {
        return { label: item.materialCode, value: item.id }
      })
      this.newMaterialNameList = data.map((item) => {
        return { label: item.materialName, value: item.id }
      })
    },
    // 库存
    async queryStockDetail(obj) {
      // 库存类型：1-固定货位库存 2-库位库存
      const params = {
        ...obj
      }
      this.tableData = []
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('queryStockDetail'),
        params
      )
      if (code) return
      this.$set(
        this.adjustDetailRow,
        'goodsPositionStatus',
        String(data.stockStatus)
      )
      this.$set(
        this.adjustDetailRow,
        'factoryPositionStatus',
        String(data.stockStatus)
      )
      this.$refs?.thirdForm?.changeFormValue(this.adjustDetailRow ?? {})
      this.tableData = data.stockDetailFlats.map((item, index) => {
        return {
          newid: index + 1,
          operateType: 2,
          adjustTarget: this.type,
          materialName: item.materialId,
          ...item
        }
      })
      // const objs = {
      //   id: 4,
      //   batchProperty: '',
      //   materialAdjustNum: null,
      //   materialAvailableNum: 0,
      //   materialBasicUnit: '',
      //   materialBatchNumber: '',
      //   materialGradeStatus: 10,
      //   materialId: null,
      //   materialName: null,
      //   materialMeasuringAdjustNum: null,
      //   materialMeasuringAvailableNum: 0,
      //   materialMeasuringUnit: '',
      //   movableContainerAdjustNum: null,
      //   movableContainerAvailableNum: null,
      //   movableContainerGoodsPositionCode: null,
      //   movableContainerGoodsPositionId: null,
      //   movableContainerId: null,
      //   movableContainerTypeId: null,
      //   turnoverContainerAdjustNum: null,
      //   turnoverContainerAvailableNum: 1,
      //   turnoverContainerId: 4,
      //   turnoverContainerTypeId: 4
      // }
      // this.tableData.push(objs)
      console.log(data)
    },
    // 切换工厂、仓库、车间、库区
    handleChange(val, formItem, cb) {
      this.$set(this.formModel, formItem.name, val)
      // console.log(this.formModel)
      if (formItem.name === 'factoryId') {
        const params = {
          factoryId: val
        }
        if (val) {
          this.getBaseData(
            'getWarehouseDictList',
            'warehouseList',
            'warehouseCode',
            params
          )
          this.getBaseData(
            'getWorkshopDictList',
            'workshopList',
            'workshopCode',
            params
          )
        }
        cb({
          warehouseId: '',
          workshopId: '',
          warehouseAreaId: '',
          factoryPositionId: '',
          goodsPositionId: ''
        })
      } else if (formItem.name === 'warehouseId') {
        this.warehouseAreaList = []
        const params = {
          warehouseId: val
        }
        this.formModel.warehouseAreaId = ''
        this.formModel.factoryPositionId = ''
        this.formModel.goodsPositionId = ''
        cb({
          warehouseAreaId: '',
          factoryPositionId: '',
          goodsPositionId: ''
        })
        if (val) {
          this.getBaseData(
            'getWarehouseAreaDictList',
            'warehouseAreaList',
            'warehouseAreaCode',
            params
          )
        }
      } else if (formItem.name === 'workshopId') {
        this.warehouseAreaList = []
        const params = {
          workshopId: val
        }
        this.formModel.warehouseAreaId = ''
        this.formModel.factoryPositionId = ''
        this.formModel.goodsPositionId = ''
        cb({
          warehouseAreaId: '',
          factoryPositionId: '',
          goodsPositionId: ''
        })
        if (val) {
          this.getBaseData(
            'getWarehouseAreaDictList',
            'warehouseAreaList',
            'warehouseAreaCode',
            params
          )
        }
      } else {
        this.factoryPositionList = []
        const params = {
          warehouseAreaId: val
        }
        if (val && this.type !== 1) {
          this.getBaseData(
            'getFactoryPositionDictList',
            'factoryPositionList',
            'factoryPositionCode',
            params
          )
          return
        }
      }
      const newParams = {
        factoryId: this.formModel.factoryId || null,
        warehouseId: this.formModel.warehouseId || null,
        workshopId: this.formModel.workshopId || null,
        warehouseAreaId: this.formModel.warehouseAreaId || null
      }
      this.getBaseData(
        'getFactoryPositionDictList',
        'factoryPositionList',
        'factoryPositionCode',
        newParams
      )
    },
    // 选择对象分类
    onSelect(val) {
      this.active = 2
      this.type = val
      this.$nextTick(() => {
        this.$refs.secondForm.clearValidate()
      })
      if (val === 1) {
        this.getBaseData(
          'getFactoryPositionDictList',
          'factoryPositionList',
          'factoryPositionCode'
        )
      } else {
        this.getContainerArchivesList()
        this.queryGoodsPositionOption()
        // this.getContainerTypeList()
      }
    },
    // 查看批属性
    async onAttr(row) {
      const params = {
        id: row.materialId
      }
      if (typeof row.batchProperty === 'string') {
        row.batchProperty = JSON.parse(row.batchProperty)
      }
      this.batchPropertyRow = row.batchProperty
      const { code, data } = await this.$httpService(
        this.$apiStore.orderManage('queryMaterialDetail'),
        params
      )
      if (code) return
      this.batchPropertyDetail = data.batchPropertyDetail || []
      if (this.batchPropertyDetail.length) {
        this.detailDialogVisible = true
      } else {
        this.$message.error(this.$t('lang.gles.orderManage.batchPropertyTips'))
      }
    },
    // 重置
    onReset() {
      if (this.type === 1) {
        this.getBaseData(
          'getFactoryPositionDictList',
          'factoryPositionList',
          'factoryPositionCode'
        )
      } else {
        this.factoryPositionId = ''
        this.containerArchivesId = ''
        this.warehouseAreaList = []
        this.factoryPositionList = []
        this.getContainerArchivesList()
        this.queryGoodsPositionOption()
      }
      this.$set(this.formModel, 'warehouseId', '')
      this.$set(this.formModel, 'workshopId', '')
      this.workshopList = []
      this.warehouseList = []
      this.$refs.secondForm.clearValidate()
    },
    onPrev() {
      console.log(this.active)
      if (this.active === 3 && this.saveTableData.length) {
        this.$confirm(this.$t('lang.gles.orderManage.isSureBack'), '', {
          confirmButtonText: this.$t('lang.gles.common.confirm'),
          cancelButtonText: this.$t('lang.gles.common.cancel'),
          type: 'warning'
        }).then(() => {
          this.active -= 1
        }).catch(() => {})
        return
      }
      this.active -= 1
      if (this.active === 1) {
        this.containerArchivesId = ''
        this.factoryPositionId = ''
        this.$set(this.formModel, 'warehouseId', '')
        this.$set(this.formModel, 'workshopId', '')
        this.workshopList = []
        this.warehouseList = []
        this.warehouseAreaList = []
        this.factoryPositionList = []
      }
    },
    onNext() {
      this.$refs.secondForm.getValidateFormModel().then(async(model) => {
        this.active += 1
        // const params = { ...model }
        this.saveTableData = []
        this.adjustDetailRow = {
          ...this.adjustDetailRow,
          ...model
        }
        // console.log(model)
        let obj = {}
        if (this.type === 1) {
          obj = {
            factoryPositionId: this.adjustDetailRow.factoryPositionId,
            type: 2
          }
        } else {
          obj = {
            goodsPositionId: this.adjustDetailRow.goodsPositionId,
            containerTypePatternType:
              this.type === 2 ? 'fixedContainer' : 'moveContainer',
            type: this.type === 2 ? 1 : null
          }
        }
        this.queryStockDetail(obj)
      })
    },
    onAdd() {
      this.mode = 'add'
      this.initRow = {
        operateType: 1
      }
      this.dialogVisible = true
    },
    editItem({ row, $index }) {
      if (typeof row.batchProperty === 'string') {
        row.batchProperty = JSON.parse(row.batchProperty)
      }
      this.mode = 'edit'
      this.initRow = row
      this.editIndex = $index
      this.dialogVisible = true
    },
    deleteItem({ $index }) {
      let deleteRow = {}
      this.tableData.forEach((item, index) => {
        if (index === $index) {
          deleteRow = item
        }
      })
      this.tableData.splice($index, 1)
      this.saveTableData.forEach((item, index) => {
        if (JSON.stringify(item) === JSON.stringify(deleteRow)) {
          this.saveTableData.splice(index, 1)
        }
      })
    },
    saveSuccess(data) {
      // console.log(this.adjustDetailRow, data)
      this.updateNewData()
      data.adjustTarget = this.type
      const obj = {
        ...this.adjustDetailRow,
        ...data
      }
      const newObj = Object.assign({}, obj)
      if (this.saveTableData.length) {
        let isRepeat = false
        delete newObj.materialAdjustNum
        delete newObj.materialMeasuringAdjustNum
        this.saveTableData.forEach(item => {
          const newItem = Object.assign({}, item)
          delete newItem.materialAdjustNum
          delete newItem.materialMeasuringAdjustNum
          delete newItem.addIndex
          // console.log(JSON.stringify(newItem), JSON.stringify(newObj))
          if (JSON.stringify(newItem) === JSON.stringify(newObj)) {
            isRepeat = true
          }
        })

        if (isRepeat) {
          this.$message.error(this.$t('lang.gles.orderManage.adjustOrderHandleTips1'))
          return
        }
      }
      // console.log(data)
      if (this.mode === 'add') {
        this.addNum++
        obj.addIndex = this.addNum
        this.tableData.push(obj)
        this.saveTableData.push(obj)
      } else {
        const ids = []
        this.saveTableData.forEach((item) => {
          ids.push(item.newid)
        })
        if (data.newid) {
          if (ids.indexOf(data.newid) !== -1) {
            this.saveTableData.forEach((item, index) => {
              if (item.newid === data.newid) {
                this.saveTableData.splice(index, 1, obj)
              }
            })
          } else {
            this.saveTableData.push(obj)
          }
        } else {
          this.saveTableData.forEach((item, index) => {
            if (item.addIndex === this.initRow.addIndex) {
              this.saveTableData.splice(index, 1, obj)
            }
          })
        }
        this.tableData.splice(this.editIndex, 1, obj)
      }
    },
    onSave() {
      if (this.saveTableData.length === 0) {
        this.$message.error(this.$t('lang.gles.orderManage.adjustOrderHandleTips2'))
        return
      }
      if (this.type !== 1) {
        let movableContainerGoodsPositionCode = null
        this.options.goodsPositionList.forEach(item => {
          if (this.adjustDetailRow.goodsPositionId === item.id) {
            movableContainerGoodsPositionCode = item.code
          }
        })
        this.saveTableData.forEach(item => {
          if (this.type === 3) {
            item.movableContainerGoodsPositionId = this.adjustDetailRow.goodsPositionId
            item.movableContainerGoodsPositionCode = movableContainerGoodsPositionCode
            item.movableContainerId = this.adjustDetailRow.containerArchivesId
            item.movableContainerTypeId = this.adjustDetailRow.containerTypeId
          } else {
            item.fixedGoodsPositionId = this.adjustDetailRow.goodsPositionId
            item.fixedContainerId = this.adjustDetailRow.containerArchivesId
          }
        })
      }
      this.$confirm(this.$t('lang.gles.orderManage.isSureBack'), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      }).then(() => {
        this.$emit('update:visible', false)
        this.$emit('saveSuccess', this.saveTableData)
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table__header-wrapper {
  overflow: auto;
}
/deep/ .el-drawer__header {
  margin-bottom: 10px;
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
  padding: 0 15px;
}
.steps-dom {
  padding-bottom: 20px;
}
.flex-c {
  display: flex;
  justify-content: space-around;
}
.drawer-wrapper {
  padding: 0;
}
.first-module {
  padding-top: 150px;
}
.second-module,
.third-module {
  padding-bottom: 55px;
}
.module-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.module-btn {
  flex-shrink: 0;
  margin-left: 5px;
}
.detail-item {
  cursor: pointer;
  color: #409eff;
}
.footer-btn {
  width: 100%;
  height: 60px;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
</style>

<template>
  <!-- 主表弹框 -->
  <m-dialog :visible="visible" width="80%" :before-save="beforeSave" @closed="closed" @save="save">
    <!-- 标题 -->
    <template #title>
      <span class="f16">
        {{ title }}
      </span>
    </template>
    <!-- 编辑 -->
    <div>
      <m-form
        v-if="mode === 'add' || !initRow.newid"
        ref="myForm"
        label-position="right"
        :extend-config="extendFormConfig"
        :label-width="140"
        :form-data="formData"
      />
      <m-form
        v-else
        ref="myForm"
        label-position="right"
        :extend-config="extendFormConfig"
        :label-width="140"
        :form-data="editFormData"
      />
      <!-- 批属性 -->
      <m-form
        v-if="batchPropertyDetail.length"
        ref="propertyForm"
        label-position="right"
        :extend-config="extendFormConfig"
        :label-width="140"
        :form-data="propertyFormData"
      />
    </div>
  </m-dialog>
</template>
<script>
import { getAdjustDetailAddFormData, getPropertyFormData, getAdjustDetailEditFormData } from '../data'
import myTransform from '@/libs_sz/utils/transform'

export default {
  props: {
    visible: Boolean,
    mode: {
      type: String,
      default: 'add'
    },
    type: {
      type: Number,
      default: 1
    },
    initRow: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      title: this.mode === 'add' ? this.$t('lang.gles.common.addNew') : this.$t('lang.gles.common.edit'),
      oldRow: {},
      extendFormConfig: {
        isNeedBtn: false
      },
      materialData: {},
      formModel: {},
      batchPropertyForm: {},
      turnoverContainerArchivesList: [],
      moveContainerArchivesList: [],
      // 批属性列表
      batchPropertyDetail: [],
      // 移动容器货位编码
      moverGoodsPositionList: [],
      newMoveContainerTypeList: []
    }
  },
  computed: {
    formData() {
      const options = this.options
      return getAdjustDetailAddFormData(this, { ...this.formModel }, options)
    },
    propertyFormData() {
      const options = this.options
      return getPropertyFormData(this, { ...this.batchPropertyForm }, options)
    },
    editFormData() {
      return getAdjustDetailEditFormData(this, { ...this.formModel }, this.options, this.initRow)
    }
  },
  created() {
    this.getContainerTypeList()
    this.formModel = { ...this.initRow }
    this.formModel.batchProperty = this.formModel.batchProperty || {}
    this.oldRow = this.dataFun(this.initRow)
    if (this.mode === 'edit') {
      this.batchPropertyForm = this.initRow.batchProperty || {}
      if (this.initRow.materialId) {
        if (!this.initRow.newid || (this.initRow.newid && !this.initRow.movableContainerId)) {
          this.queryMaterialDetail(this.initRow.materialId)
        }
      }
      if (this.initRow.movableContainerId) {
        const params = {
          containerArchivesId: this.initRow.movableContainerId
        }
        this.getGoodsPositionListByType(params)
      }
      if (this.initRow.movableContainerTypeId) {
        this.getContainerArchivesList(this.initRow.movableContainerTypeId, 'moveContainerArchivesList', 'moveContainer')
      }
      if (this.initRow.turnoverContainerTypeId) {
        this.getContainerArchivesList(this.initRow.turnoverContainerTypeId, 'turnoverContainerArchivesList', 'turnoverContainer')
      }
    }
  },
  methods: {
    // 获取容器类型
    async getContainerTypeList() {
      const queryData = [
        'containerPatternTray'
      ]
      const params = {
        containerTypePatternList: queryData
      }
      const { code, data } = await this.$httpService(
        this.$apiStore.materialManage('getContainerType'),
        params
      )
      if (code) return
      this.newMoveContainerTypeList = myTransform.arrToOptions(
        data,
        'containerTypeName',
        'containerTypeId'
      )
    },
    dataFun(data) {
      const obj = this._.cloneDeep(data)
      for (const key in obj) {
        if (obj[key] == null) {
          obj[key] = ''
        }
        if (!isNaN(obj[key]) && obj[key] !== '') {
          obj[key] = Number(obj[key])
        }
      }
      return obj
    },
    handleChange(val, formItem, cb) {
      this.$set(this.formModel, formItem.name, val)
      if (formItem.name === 'materialId') {
        this.batchPropertyDetail = []
        if (val) {
          this.queryMaterialDetail(val)
        }
        cb({
          materialName: val
        })
      }
      if (formItem.name === 'movableContainerId') {
        const params = {
          containerArchivesId: val
        }
        cb({
          movableContainerGoodsPositionId: ''
        })
        this.getGoodsPositionListByType(params)
      }
    },
    propertyChange(val, formItem) {
      this.$set(this.batchPropertyForm, formItem.name, val)
    },
    validateChange() {
      this.$refs.myForm.getValidateFormModel().then(() => {}).catch(() => {})
    },
    // 移动容器货位编码
    async getGoodsPositionListByType(params = {}) {
      const { data } = await this.$httpService(this.$apiStore.base('getGoodsPositionListByType'), params)
      const list = data.map(item => ({
        label: item.code,
        value: item.id,
        ...item
      }))
      this.moverGoodsPositionList = list
    },
    // 查询批属性列表
    async queryMaterialDetail(id) {
      const params = {
        id: id
      }
      const { code, data } = await this.$httpService(this.$apiStore.orderManage('queryMaterialDetail'), params)
      if (code) return
      this.materialData = data
      this.batchPropertyDetail = data.batchPropertyDetail || []
    },
    // 获取容器编码(周转容器和移动容器类型用)
    async getContainerArchivesList(id, array, type) {
      const params = {
        containerTypeId: id,
        containerTypePatternType: type,
        inStore: false
      }
      const { code, data } = await this.$httpService(this.$apiStore.orderManage('getContainerArchivesList'), params)
      if (code) return
      this[array] = data && data.map(item => { return { label: item.containerArchivesCode, value: item.id } })
    },
    // 容器类型选择
    dialogContainerTypeChange(val, formItem, cb) {
      console.log(val, formItem)
      const array = formItem.name === 'movableContainerTypeId' ? 'moveContainerArchivesList' : 'turnoverContainerArchivesList'
      const containerTypePatternType = formItem.name === 'movableContainerTypeId' ? 'moveContainer' : 'turnoverContainer'
      this[array] = []
      if (formItem.name === 'movableContainerTypeId') {
        cb({
          movableContainerId: '',
          movableContainerGoodsPositionId: ''
        })
      } else {
        cb({
          turnoverContainerId: ''
        })
      }
      if (val) {
        this.getContainerArchivesList(val, array, containerTypePatternType)
      }
    },
    // 保存前
    beforeSave() {
      // 验证表单
      return new Promise((resolve, reject) => {
        this.$refs.myForm.getValidateFormModel().then((model) => {
          if (!model.movableContainerId && !model.turnoverContainerId && !model.materialId) {
            this.$message.error(this.$t('lang.gles.orderManage.pleasePerfectAdjustOrder'))
            reject()
            return
          }
          if (this.batchPropertyDetail.length) {
            this.$refs.propertyForm.getValidateFormModel().then(() => {
              this.formModel = {
                ...this.formModel,
                ...model,
                batchProperty: this.batchPropertyForm
              }
              if (this.mode === 'add') {
                if (this.formModel.movableContainerId) {
                  this.formModel.movableContainerAdjustNum = 1
                }
                if (this.formModel.turnoverContainerId) {
                  this.formModel.turnoverContainerAdjustNum = 1
                }
              }
              this.formModel = this.dataFun(this.formModel)
              console.log(this.formModel, this.oldRow, this._.isEqual(this.formModel, this.oldRow))
              if (this.mode === 'edit' && JSON.stringify(this.oldRow) === JSON.stringify(this.formModel)) {
                this.$message.error(this.$t('lang.gles.orderManage.adjustOrderHandleTips3'))
                reject()
                return
              }
              resolve()
            }).catch(() => reject())
          } else {
            this.formModel = {
              ...this.formModel,
              ...model
            }
            if (this.mode === 'add') {
              if (this.formModel.movableContainerId) {
                this.formModel.movableContainerAdjustNum = 1
              }
              if (this.formModel.turnoverContainerId) {
                this.formModel.turnoverContainerAdjustNum = 1
              }
            }
            this.formModel = this.dataFun(this.formModel)
            console.log(this.formModel, this.oldRow, this._.isEqual(this.formModel, this.oldRow))
            if (this.mode === 'edit' && JSON.stringify(this.oldRow) === JSON.stringify(this.formModel)) {
              this.$message.error(this.$t('lang.gles.orderManage.adjustOrderHandleTips3'))
              reject()
              return
            }
            resolve()
          }
        }).catch(() => reject())
      })
    },
    // 保存成功关闭弹框
    save() {
      this.formModel.materialBasicUnit = this.materialData.basicUnit
      this.formModel.materialMeasuringUnit = this.materialData.measuringUnit
      this.$emit('saveSuccess', this.formModel)
    },
    // 关闭
    closed() {
      this.$emit('update:visible', false)
    }
  }
}
</script>
<style lang="scss">
.ui-containerBox .el-input--prefix .el-input__inner {
  padding-left: 15px;
}
</style>
